@import '_fonts.css';
@import '_colors.css';
@import '_mixins.css';

.linked-family-members {
  &__select {
    @mixin select-styles;

    &:focus-within {
      @mixin custom-focus-visible;
    }
  }

  &__select-root {
    padding-top: 0.25rem;
    padding-right: 2rem;
    padding-bottom: 0.25rem;
    padding-left: 0.875rem;

    &:global(.MuiInput-input.MuiSelect-select) {
      padding-right: 2.125rem;
    }

    &:focus {
      background-color: transparent;
    }
  }

  &__value {
    display: flex;
    align-items: center;
    color: $cool-gray-20;
    font-size: $font-size-m;
  }

  &__count {
    width: 2rem;
    height: 1.5rem;
    margin-left: 2rem;
    font-weight: 700;
    font-size: $font-size-s;
    background-color: $magenta-0;
  }

  &__outlined {
    border: 2px solid $primary-green;
  }
}
