@import '_sizes.css';
@import '_fonts.css';
@import '_z-index.css';
@import '_mixins.css';

.main-header {
  z-index: $z-index-header;
  box-sizing: border-box;
  font-size: $font-size-l;
  background: $white;

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__left-content {
    display: flex;
    align-items: center;
  }

  &__mobile-menu {
    display: none;

    @media (max-width: $screenTabletMax) {
      display: block;
    }
  }

  &__menu-icon {
    margin: 0;
  }

  &__menu-button {
    padding: 0;

    &:focus-visible {
      @mixin custom-focus-visible;
    }
  }

  &__logo-icon {
    width: 11.5rem;
    height: 3.75rem;
    cursor: pointer;
    pointer-events: none;

    @media (max-width: $screenTabletMax) {
      margin-left: 19.5px;
    }
  }

  &__environment-notification {
    margin-left: 2rem;

    @media (max-width: $screenMobileMax) {
      margin-left: 0.75rem;
    }
  }

  &__right-content {
    display: flex;
    align-items: center;

    @media (max-width: $screenTabletMax) {
      display: none;
    }
  }

  &__profile-button {
    display: none;

    @media (max-width: $screenTabletMax) {
      display: flex;
      align-items: center;
    }
  }

  &__content-item {
    position: relative;
    margin-right: 3.125rem;
    font-weight: 600;

    &:last-of-type {
      margin-right: 0;
    }

    &--button {
      font-weight: 600;
    }
  }

  &__message-circle {
    position: absolute;
    top: 0.35rem;
    left: 1.7rem;
    width: 0.5rem;
    height: 0.5rem;
    background: $magenta-0;
    border: 1px solid $white;
    border-radius: 50%;
  }
}
