@import '_colors.css';
@import '_sizes.css';

.root {
  display: flex;
  margin-top: 2.5rem;
  list-style-type: none;

  @media (max-width: $screenMobileMax) {
    flex-direction: column;
  }
}

.icon {
  margin-right: 1.5rem;

  @media (max-width: $screenMobileMax) {
    margin: 0 auto 2.5rem;
  }
}

.title {
  margin-bottom: 1rem;
  color: $cool-gray-0;
}

.sub-title {
  color: $cool-gray-20;
}
