@import '_sizes.css';
@import '_colors.css';
$link-padding: 1.5rem;

@define-mixin menu-item-underline {
  &::after {
    position: absolute;
    top: calc(100% + 0.15rem);
    right: 0;
    left: 0;
    display: block;
    box-sizing: border-box;
    height: 4px;
    background-color: $primary-green;
    content: '';
  }
}

.mobile-submenu {
  &__sub-item {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  &__item {
    width: 23rem !important;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    &--active {
      text-decoration-line: none !important;
    }
  }

  &__item-inner {
    position: relative;

    &--active {
      @mixin menu-item-underline;
    }
  }

  &__item-chevron {
    float: right;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__back-chevron {
    float: left;
    margin-top: auto;
    margin-right: 1rem;
    margin-bottom: auto;
    margin-left: 1.5rem;
  }

  &__item-content {
    display: flex;
  }
}
