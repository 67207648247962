@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';

.attachments {
  margin-right: 0.3rem;
}

.item {
  display: block;
  padding: 0.5rem 0;
  line-height: 1.5rem;
  white-space: break-spaces;
  word-break: break-word;
  border-bottom: 1px solid $cool-gray-plus-60;

  &--active {
    background: $primary-green-plus-90;
    border-bottom: 1px solid transparent;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 3px;
      background: $primary-green;
      content: '';
    }
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.sender-name {
  display: flex;
  font-size: $font-size-m;

  &--active {
    font-weight: 600;
  }

  &--unread {
    font-weight: 600;

    &::before {
      align-self: center;
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.5rem;
      background: $magenta-0;
      border-radius: 50%;
      content: '';
    }
  }
}

.recent-date-container {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  font-size: $font-size-m;

  &--unread {
    font-weight: 600;
  }
}

.subject-name {
  font-size: $font-size-s;
}

.skeleton {
  min-width: 3rem;
}
