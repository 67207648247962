@import '_colors.css';

.toggle-button-group {
  &--separated {
    &:global(.MuiToggleButtonGroup-root) {
      :global(.MuiToggleButtonGroup-grouped) {
        &:not(:first-of-type),
        &:not(:last-of-type) {
          border: 2px solid $purple-10;
          border-radius: 8px;

          &:global(.Mui-selected) {
            &:hover {
              border-color: $purple-30;
            }
          }
        }
      }
    }
  }
}
