@import '_colors';
@import '_fonts';
@import '_sizes.css';

@define-mixin icon-styles {
  width: 1.25rem;
  height: 1.25rem;
}

@define-mixin filled-styles $bgColor {
  &:global(.MuiAlert-filled) {
    color: $white;
    background: $bgColor;
    border-color: $bgColor;
  }
}

.alert {
  font-weight: 400;
  font-size: $font-size-s;
  border: 1px solid;
  border-radius: 0.5rem;

  &--success {
    color: $success-20;
    background: $success-plus-45;
    border-color: $success-10;

    @mixin filled-styles $success-10;
  }

  &--error {
    color: $error-red-40;
    background: $error-red-plus-80;
    border-color: $error-red-40;

    @mixin filled-styles $error-red-40;
  }

  &--info {
    color: $teal-40;
    background: $teal-plus-90;
    border-color: $teal-0;

    @mixin filled-styles $teal-0;

    &:global(.MuiAlert-standard) {
      padding: 0;
      background: transparent;
      border: none;
    }
  }

  &--alert {
    color: $error-0;
    background: $error-plus-45;
    border-color: $error-20;

    @mixin filled-styles $error-20;

    &:global(.MuiAlert-standard) {
      padding: 0;
      background: transparent;
      border: none;
    }
  }

  &--warning,
  &--impersonate {
    color: $cool-gray-20;
    background: $gold-plus-30;
    border-color: $gold-20;

    @mixin filled-styles $gold-20;
  }

  &--small {
    line-height: 1.6;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    font-weight: 600;
    font-size: $font-size-m;
  }

  &:global(.MuiAlert-filled) &__icon {
    color: $white;
    fill: $white;
    stroke: $white;
  }

  &__icon {
    @mixin icon-styles;

    &--success {
      color: $success-10;
      fill: $success-10;
      stroke: $success-10;
    }

    &--error {
      color: $error-0;
      fill: $error-0;
    }

    &--info {
      color: $blue-10;
      fill: $blue-0;
      stroke: $blue-0;
    }

    &--warning,
    &--impersonate {
      color: $gold-20;
      fill: $gold-20;
      stroke: $gold-20;
    }

    &--small {
      padding: 0;
    }

    &--alert {
      color: $error-0;
    }

    &--title {
      padding-top: 6px;
    }
  }

  &__message {
    width: 100%;
    font-weight: 400;

    &--small {
      padding: 0;
    }
  }

  &__body {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}
