@import '_fonts.css';
@import '_sizes.css';
@import '_mixins.css';

.message-box {
  flex: 1 1 auto;
  width: 100%;

  &__skeleton {
    min-width: 5rem;
  }

  &__message-list {
    margin: 0;
    padding: 0;
  }

  &__message {
    display: block;
    margin: 0;
    padding: 2.5rem 0;
    font-size: inherit;
    line-height: inherit;
    border-bottom: 1px solid $cool-gray-plus-30;
    cursor: default;

    &:last-child {
      border-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:focus-within {
      background-color: transparent;
    }

    &:focus-visible {
      @mixin custom-focus-visible;
    }
  }

  &__header-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screenTabletMax) {
      flex-direction: column-reverse;
    }
  }

  &__actions {
    display: flex;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    justify-content: flex-end;
    justify-items: flex-end;

    @media (max-width: $screenTabletMax) {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.5rem;
    }
  }

  &__action-button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__back-button {
    padding: 0;

    &:hover {
      svg {
        color: $primary-green;
      }
    }
  }

  &__header {
    white-space: break-spaces;
  }

  &__subject {
    color: $cool-gray-20;
    font-weight: normal;
    font-size: $font-size-xl;
    line-height: 1.75rem;
  }

  &__sent-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__sender,
  &__sent {
    margin-right: 2.5rem;
    color: $cool-gray-20;
    font-weight: 600;
    font-size: $font-size-m;
    line-height: 1.75rem;
  }

  &__attachments {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    color: $cool-gray-plus-20;
    font-size: $font-size-m;
    line-height: 1.75rem;
  }

  &__attachment-title {
    margin-right: 0.25rem;
  }

  &__attachment {
    margin: 0.25rem;
    border: 1px solid $cool-gray-0;
  }

  &__body {
    margin-top: 1.5rem;
    color: $cool-gray-20;
    font-size: $font-size-m;

    img {
      max-width: 100%;
    }
  }
}
