@import '_sizes.css';

.container {
  padding: 1.5rem 0.5rem 0.5rem;
}

.title {
  margin-bottom: 1.25rem;
}

.button {
  width: 100%;
  margin-top: 1.5rem;
}
