.tabs {
  &__indicator {
    display: none;
  }

  &__root,
  &__container {
    width: 100%;
  }
}
