@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';

.errors {
  &__alert {
    margin-bottom: 1rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  &__action-button {
    margin-left: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
}
