@import '_colors.css';
@import '_fonts.css';

.history-list {
  &__title {
    padding: 1rem 0;
    color: $cool-gray-plus-20;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-top: 1px solid $cool-gray-plus-30;

    &:last-child {
      border-bottom: 1px solid $cool-gray-plus-30;
    }
  }

  &__no-entries {
    color: $cool-gray-plus-20;
    font-size: $font-size-xl;
  }

  &__item-sub-header {
    color: $cool-gray-plus-20;
  }

  &__source {
    text-transform: capitalize;
  }

  &__icons {
    display: flex;
    color: $cool-gray-plus-20;
  }

  &__icon {
    margin-right: 1.5rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
