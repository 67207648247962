@import '_sizes.css';

@define-mixin sidebar {
  flex: 0 0 auto;
  box-sizing: border-box;
  width: $sidebarDesktopMaxWidth;

  @media (max-width: $screenTabletMax) {
    width: inherit;
    margin: 0;
  }
}

.inner-layout {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: $screenDesktopMin;
  margin: auto;

  @media (max-width: $screenTabletMax) {
    flex-direction: column;
    padding: 0 1rem;
  }

  @media (max-width: $screenMobileMax) {
    flex-direction: column;
  }

  &__left-sidebar {
    margin-right: 1.5rem;

    @mixin sidebar;
  }

  &__right-sidebar {
    margin-left: 1.5rem;

    @mixin sidebar;
  }

  &__content {
    flex: 1 1 auto;
    width: 100%;
  }
}
