@import '_colors.css';
@import '_fonts.css';
@import '_mixins.css';
@import '_sizes.css';

.paper {
  max-width: 36.5rem;
}

.title {
  color: $cool-gray-0;
  font-weight: 600;
  font-size: $font-size-xxxl;
}

.description {
  margin-bottom: 1.5rem;
}

.asterisk {
  @mixin field-asterisk;
}

.required-hint {
  margin-top: 0.25rem;
  margin-bottom: 2rem;
  color: $cool-gray-0;
  font-size: $font-size-xs;
}

.description-container {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media (max-width: $screenMobileMax) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.button {
  margin-left: 1rem;
  line-height: 1rem;

  @media (max-width: $screenMobileMax) {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0;
  }
}

.hidden-attachments {
  display: none;
}

.attachment {
  max-width: 12.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid $cool-gray-0;
}

.attachment-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.alert {
  font-weight: 600;
}
