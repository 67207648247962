@import '_fonts.css';

.application-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  align-self: center;
  padding: 1rem 0 1.5rem;

  &__button-container {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;
  }

  &__button {
    pointer-events: none;
  }

  &__link {
    display: inline-block;
    margin-right: 0.5rem;
    cursor: pointer;

    :last-child {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}
