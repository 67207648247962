@import '_sizes.css';
@import '_colors.css';

.required-signature {
  display: grid;
  gap: 1.25rem;
}

.hint {
  color: $gray-20;
  text-transform: uppercase;

  & span {
    text-transform: none;
  }
}

.signature {
  width: 50%;

  @media (max-width: $screenMobileMax) {
    width: 100%;
  }
}
