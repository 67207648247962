@import '_colors.css';
@import '_fonts.css';

@define-mixin outlined-style $color: $magenta-0 {
  color: $color;
  font-weight: 700;
  font-size: 1rem;
  background-color: transparent;
  border: 1px solid $color;
}

.number-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  color: $white;
  line-height: 1.5rem;
  background-color: $magenta-0;
  border-radius: 1.25rem;

  &--outlined {
    @mixin outlined-style $magenta-0;
  }

  &--outlined&--deep-blue {
    @mixin outlined-style $deep-blue;
  }

  &--outlined&--purple {
    @mixin outlined-style $purple;
  }

  &--medium {
    width: 4.5rem;
    height: 2rem;
    font-size: $font-size-xl;
  }

  &--short {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }

  &--purple {
    background: $purple-10;
  }

  &--dark-blue {
    background: $deep-blue;
  }

  &--large {
    width: 2.5rem;
    height: 2.5rem;
    font-size: $font-size-xxxl;
  }
}
