@import '_fonts.css';
@import '_colors.css';

$link-padding: 1rem;

.tab {
  position: relative;
  padding: 1rem;
  color: $cool-gray-0 !important;
  font-weight: 500;
  font-size: $font-size-l;
  font-family: $main-font-family;
  text-transform: capitalize;
  opacity: 1;

  &--hover,
  &:hover {
    background-color: $primary-green-plus-90;
  }

  &--selected-background {
    color: $white !important;
    background: $primary-green;

    &:first-child {
      border-color: transparent;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-color: transparent;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}
