@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';
@import '_mixins.css';

.watermark {
  position: absolute;
  right: 0;
  bottom: 0;
  width: fit-content;
  height: fit-content;
}

.form {
  position: relative;
  display: flex;
  flex: 1 1 45%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-top: -2.75rem;
  padding-bottom: 12rem;

  @mixin marathon-fire-background 30rem, 30rem, -13rem, -4rem;

  background-color: $primary-green-plus-80-opacity-50;

  @media (max-width: $screenTabletMax) {
    justify-content: flex-start;
    width: 100%;
    min-width: auto;
    max-width: none;
  }

  @media (max-width: $screenMobileMax) {
    padding: 0 2rem;
    background: unset;
  }

  &__container {
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    max-width: 28rem;
    margin-top: 3rem;
    background: var(--white-opacity-100, #ffffff);
    border-radius: 16px;
    box-shadow: 0 20px 48px 0 rgb(16 75 57 / 50%), 0 24px 48px 0 rgb(108 118 132 / 8%);

    @media (max-width: $screenTabletMax) {
      margin-top: -2rem;
    }

    @media (max-width: $screenMobileMax) {
      max-width: unset;
      background: var(--white-opacity-100, #ffffff);
      border-radius: 16px;
      box-shadow: 0 12px 24px 0 rgb(16 75 57 / 50%), 0 6px 12px 0 rgb(108 118 132 / 8%);
    }
  }

  &__inner-container {
    padding: 1.5rem;
    padding-bottom: 2.5rem;
    background: $white;
    border-radius: 1rem;
  }

  &__title {
    margin-bottom: 1.5rem;
  }

  &__field {
    width: 100%;
  }

  &__field-row {
    padding-bottom: 1rem;
  }

  &__button {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  &__action-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    color: $cool-gray-20;
    font-size: $font-size-s;
  }

  &__need-help {
    margin: 2.875rem 0 2.5rem;
    color: $cool-gray-20;
    text-align: center;
  }
}
