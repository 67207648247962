@define-mixin link {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

@define-mixin link-color $color, $hover-color {
  color: $color;
  text-decoration-color: $color;

  &:hover {
    color: $hover-color;
    text-decoration-color: $hover-color;
  }
}

@define-mixin reverse-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
