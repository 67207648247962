@import '_sizes.css';

.record-activity-modal {
  &__paper {
    max-width: 45rem;
  }

  &__content {
    @media (max-width: $screenMobileMax) {
      height: 100%;
    }
  }
}
