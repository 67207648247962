@import '_sizes.css';
@import '_colors.css';
@import '_fonts.css';

@define-mixin button-shadow $radius {
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%);
}

$outline-border-width: 1px;

/* For safari outline fix [MHPF-674] */
@define-mixin outline-mixin $radius, $borderWidth: 1px, $borderColor: $cool-gray-plus-30 {
  position: relative;
  border-radius: $radius;

  &::before {
    position: absolute;
    top: calc(-1 * $borderWidth);
    right: calc(-1 * $borderWidth);
    bottom: calc(-1 * $borderWidth);
    left: calc(-1 * $borderWidth);
    border: $borderWidth solid $borderColor;
    border-radius: calc($radius + 1px);
    content: '';
  }
}

/* For safari outline fix [MHPF-674] */
@define-mixin border $radius, $borderWidth: 1px, $borderColor: $cool-gray-plus-30 {
  border-radius: $radius;
  box-shadow: 0 0 0 $borderWidth $borderColor;
}

@define-mixin double-border $radius, $borderColor: $cool-gray-plus-30 {
  border-radius: $radius;
  box-shadow:
    0 0 0 3px white,
    0 0 0 5px $borderColor;
}

@define-mixin double-border-focused $radius, $borderColor: $cool-gray-plus-30 {
  &:focus,
  &:focus-visible {
    @mixin double-border 8px, $cool-gray-plus-20;
  }
}

@define-mixin text-shadow {
  text-shadow:
    0 2px 4px rgb(0 0 0 / 25%),
    0 4px 8px rgb(26 25 25 / 50%);
}

@define-mixin header-padding {
  padding: 0.75rem $app-bap-general-padding;

  @media (max-width: $screenTabletMax) {
    padding: 1.5rem $app-bap-general-tablet-padding;
  }

  @media (max-width: $screenTabletMax) {
    padding: $app-bap-general-mobile-padding;
  }
}

@define-mixin menu-item-hover {
  &:hover {
    background-color: $primary-green-plus-90;
    cursor: pointer;
    text-decoration-style: solid;
    text-decoration-color: $purple-10;

    span,
    a,
    div > a,
    div > span,
    a > div {
      text-decoration: underline;
    }

    * {
      text-decoration-color: $purple-10;
    }
  }
}

@define-mixin inner-underline {
  text-decoration-color: transparent;
  background: $primary-green-plus-90;

  span,
  a {
    text-decoration: underline;
    text-decoration-color: $cool-gray-20 !important;
  }
}

@define-mixin field-label {
  color: $cool-gray-20;
  font-size: $font-size-xs;
  transform: translate(0, -1.5px) scale(1);
}

@define-mixin field-label-focused {
  color: $purple !important;
}

@define-mixin field-focused {
  &::after {
    border-bottom-color: $purple;
  }
}

@define-mixin field-asterisk {
  color: $orange-0;
  font-size: $font-size-xs;
}

@define-mixin checkbox-label {
  color: $cool-gray-20;
  font-size: $font-size-xl;
}

@define-mixin input-styles {
  color: $cool-gray-20;
  font-size: $font-size-m;

  &::placeholder {
    color: $cool-gray-plus-20;
    font-size: $font-size-m;
  }
}

@define-mixin article-container-styles {
  display: flex;
  justify-content: flex-start;

  @media (min-width: $screenLaptopMax) {
    width: 70%;
    margin: auto;
  }
}

@define-mixin article-container-content-styles {
  width: 100%;
  padding: 1.25rem;
  padding-top: 0.25rem;

  a {
    color: $purple-10;
  }

  @media (max-width: $screenMobileMax) {
    padding: 0;
  }
}

@define-mixin article-heading-styles {
  margin: 1rem 0;
}

@define-mixin article-paragraph-styles {
  color: $cool-gray-20;
  font-weight: 400;
  font-size: $font-size-m;
  line-height: 1.25rem;
  word-break: break-word;
}

@define-mixin challenges-modals {
  max-width: 48.5rem;
  margin: auto;

  @media (max-width: $screenMobileMax) {
    max-width: none;
  }
}

@define-mixin card-hover $color: $primary-green {
  box-sizing: border-box;
  box-shadow: 0 2px 4px $black-opacity-15;
  transform: translateY(-2px);
  transition: transform 0.2s;

  @mixin outline-mixin 8px, 2px, $color;
}

@define-mixin guidelines-button {
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  color: $cool-gray-0;
  text-align: left;
  border: 1px solid $cool-gray-plus-30;
  border-radius: 0.5rem;

  &:hover {
    background: $primary-green-plus-90 !important;
    transform: translateY(-2px);
    transition: transform 0.2s;
  }
}

@define-mixin list-button {
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  color: $cool-gray-20;
  font-weight: 400;
  font-size: $font-size-xl;
  text-align: left;
  border: 2px solid $cool-gray-20;
  border-radius: 8px;

  &:hover {
    transform: translateY(-2px);
    transition: transform 0.2s;
  }
}

@define-mixin list-skeleton {
  height: 3.625rem;
  transform: none;
}

@define-mixin error-label {
  color: $error-0;
  background-color: $error-0;
  border-color: $error-0;
}

@define-mixin success-label {
  color: $mint-10;
  background-color: $mint-10;
  border-color: $mint-10;
}

@define-mixin warning-label {
  color: $gold-0;
  background-color: $gold-0;
  border-color: $gold-0;
}

@define-mixin disabled-label {
  color: $cool-gray-plus-20;
  background-color: $cool-gray-plus-20;
  border-color: $cool-gray-plus-20;
}

@define-mixin normal-label {
  color: $purple-10;
  background-color: $purple-10;
  border-color: $purple-10;
}

@define-mixin auth-pages {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
}

@define-mixin custom-focus-visible $offset: 2px {
  outline: 2px solid $cool-gray-plus-20;
  outline-offset: $offset;
}

@define-mixin custom-focus-visible $offset: 2px, $color: $cool-gray-plus-20 {
  outline: 2px solid $color;
  outline-offset: $offset;
}

@define-mixin sticky-under-main-header $margin-from-header {
  position: sticky;
  top: calc(8.625rem + $margin-from-header);

  @media (max-width: $screenTabletMax) {
    top: calc(6rem + $margin-from-header);
  }
}

@define-mixin select-styles {
  box-sizing: border-box;
  overflow: hidden;
  border: 2px solid $cool-gray-0;
  border-radius: 6px;

  &:hover {
    background-color: $primary-green-plus-90;
    border-color: $primary-green;
  }

  &:global(.Mui-focused) {
    border-color: $primary-green;
    border-radius: 6px;
  }

  & :global(.MuiSelect-icon) {
    top: unset;
    right: 0.375rem;
  }
}

@define-mixin marathon-fire-background $size: 35rem, $tabletSize: 25rem, $bottom: -10.25rem, $right: -1.125rem {
  background-color: $white;
  background-image: url('@assets/mh-watermark.svg');
  background-repeat: no-repeat;
  background-position: bottom $bottom right $right;
  background-size: $size;

  @media (max-width: $screenTabletMax) {
    background-size: $tabletSize;
  }

  @media (max-width: $screenMobileMax) {
    background: $white;
  }
}

@define-mixin text-hover {
  text-decoration: underline;
  text-decoration-color: $purple-10;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.375rem;
}

@define-mixin small-action-link {
  display: inline-block;
  min-width: auto;
  padding: 0;
  font-weight: 400;
  font-size: $font-size-s;
}

@define-mixin fa-purple-inverted-hover {
  --fa-primary-color: $white;
  --fa-secondary-color: $purple-plus-40;
  --fa-secondary-opacity: 1;
}

@define-mixin flex-column $gap: 1rem {
  display: flex;
  flex-direction: column;
  gap: $gap;
}
