@import '_sizes.css';

.paper {
  max-width: 19rem;
  padding-top: 1rem;

  @media (max-width: $screenMobileMax) {
    max-width: none;
  }
}
