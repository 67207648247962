@import '_colors.css';
@import '_sizes.css';

.base-layout {
  display: flex;
  flex-direction: column;
  min-width: $screenMobileMin;
  min-height: 100vh;

  &__header {
    position: sticky;
    top: 0;
    z-index: 4;
    flex: 0 0 auto;
  }

  &__main-content {
    z-index: 1;
    flex: 1 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: $screenMobileMax) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__paper {
    width: 69%;
    padding: 1.5rem;
  }

  &__footer {
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 100%;
    max-width: $screenDesktopMin;
    margin: auto;
  }
}
