@import '_mixins.css';

.radio {
  &__label {
    @mixin checkbox-label;

    margin: 0;
    fill: none;

    &--disabled {
      opacity: 0.5;
    }

    &--with-sub-label {
      margin-bottom: 0.5rem;
    }
  }

  &__sub-label {
    position: absolute;
    font-size: $font-size-xs;
    white-space: nowrap;
  }

  &__input {
    svg {
      fill: $primary-green;
    }

    &--disabled {
      opacity: 0.5;

      svg {
        fill: $cool-gray-plus-40 !important;
      }
    }

    &--checked {
      svg {
        fill: $primary-green !important;
      }
    }
  }

  &__focus-visible svg {
    @mixin custom-focus-visible;

    border-radius: 50%;
    outline-color: $primary-green;
  }
}
