.dropdown-menu-item {
  margin: 0.5rem 0;
  padding: 0.8rem 0;

  &--half-padding {
    padding: 0.4rem 0;
  }

  &__text {
    width: 100%;
  }

  &__divider {
    margin: 8px 0;

    &-wrapper {
      list-style: none;
    }
  }
}
