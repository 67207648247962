@import '_colors.css';
@import '_mixins.css';

.toggle-button {
  &:global(.MuiToggleButton-root) {
    color: $purple-10;
    text-transform: none;
    border: 2px solid $purple-10;
    border-radius: 8px;

    &:global(.Mui-selected) {
      color: $white;
      background-color: $purple-10;

      &:hover {
        background-color: $purple-30;
        border-color: $purple-30;
      }
    }

    &:global(.Mui-focusVisible) {
      @mixin custom-focus-visible;
    }
  }
}
