@import '_colors.css';
@import '_sizes.css';
@import '_links.css';

html {
  font-size: 16px;

  @media (max-width: $screenLaptopMax), (max-height: 780px) and (min-width: $screenDesktopMin) {
    font-size: 14px;
  }
}

a {
  @mixin link;
}

body {
  min-height: 100vh;
  margin: 0;
  color: $cool-gray-0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $cool-gray-plus-90;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

ul,
ol {
  padding: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

.react-swipeable-view-container {
  will-change: inherit !important;
}
