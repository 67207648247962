@import '_colors.css';
@import '_sizes.css';
@import '_fonts.css';

.container {
  padding: 0.5rem 5rem;
}

.header {
  margin-bottom: 0.625rem;
  font-size: $font-size-huge;
}

.sub-header {
  margin-bottom: 0.375rem;
  font-weight: 300;
  font-size: $font-size-xxxxxl;
  text-transform: uppercase;
}

.third-header {
  margin: 1.875rem 0 0.625rem;
  font-weight: 300;
  font-size: $font-size-xxxl;
}

.paragraph {
  margin-bottom: 0.625rem;
  font-size: $font-size-m;

  a {
    color: inherit;
  }
}
