@import '_colors.css';
@import '_sizes.css';
@import '_fonts.css';

@define-mixin flex-styles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.connect-my-device-modal {
  &__container {
    word-break: break-word;
  }

  &__content {
    @mixin flex-styles;

    flex-direction: column;
    padding: 2.5rem 2rem;
  }

  &__show-spinner {
    height: 100%;
  }

  &__header-container {
    @mixin flex-styles;

    margin-right: 0.75rem;
    margin-bottom: 1.25rem;
    margin-left: 0.81rem;
  }

  &__icon {
    width: 3.9rem;
    height: 6.25rem;
    margin-right: 1.875rem;

    @media (max-width: $screenMobileMax) {
      width: 2.31rem;
      height: 3.75rem;
      margin-right: 0;
    }
  }

  &__heading {
    color: $cool-gray-20;
    font-weight: 400;
    font-size: $font-size-xxxxxl;

    @media (max-width: $screenMobileMax) {
      width: 60%;
    }
  }

  &__sub-text {
    margin-top: 0.6rem;
    margin-bottom: 0.8rem;
    color: $cool-gray-20;
    font-weight: 400;
    font-size: $font-size-xl;

    @media (max-width: $screenMobileMax) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__actions-container {
    @mixin flex-styles;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    background: #e7e9e9;
    border-radius: 0.5rem;

    @media (max-width: $screenMobileMax) {
      flex-direction: column;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }

  &__actions-text {
    width: 62%;
    color: $cool-gray-20;
    font-weight: 400;
    font-size: $font-size-xl;

    @media (max-width: $screenMobileMax) {
      width: 100%;
      margin-bottom: 1.1rem;
    }
  }

  &__footer {
    @mixin flex-styles;
  }

  &__footer-icon {
    margin-right: 0.75rem;
    fill: $cool-gray-plus-20;
  }

  &__footer-text {
    color: $cool-gray-plus-20;
    font-weight: 400;
    font-size: $font-size-s;
  }

  &__close-button {
    top: 0.8rem;
    right: 0.8rem;
  }

  &__close-icon {
    width: 1.2rem;
    height: 1.2rem;
    fill: $cool-gray-20;
  }

  &__connect-button {
    width: 8rem;

    @media (max-width: $screenMobileMax) {
      width: 100%;
    }
  }

  &__link {
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    width: 8.68rem;
    height: 3.06rem;
    pointer-events: none;
  }

  &__buttons-container {
    width: 8.625rem;

    @media (max-width: $screenMobileMax) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__header-mobile {
    @mixin flex-styles;

    width: 100%;
  }

  &__header-mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__spinner-container {
    height: 26rem;
  }

  &__modal {
    width: 43.81rem;
    margin: auto;

    @media (max-width: $screenMobileMax) {
      width: 100%;
    }
  }
}
