@import '_sizes.css';
@import '_colors.css';

.impersonation-banner {
  z-index: 2;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: $screenDesktopMin;
  margin: 0.25rem auto -1.75rem;
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid $gold-20;
  border-radius: 8px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    align-items: center;
  }

  &__message {
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__spinner {
    width: 1rem;
    height: 1rem;
  }
}
