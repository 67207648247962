@import '_colors.css';
@import '_fonts.css';

.pagination {
  &__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    list-style: none;
  }
}
