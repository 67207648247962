@import '_colors.css';
@import '_mixins.css';

.label {
  @mixin field-label;

  &--focused {
    @mixin field-label-focused;
  }

  &--outlined {
    top: -1.5rem;
  }

  &--disabled {
    color: $color-label-disabled !important;
  }
}

.label-asterisk {
  @mixin field-asterisk;
}
