@import '_sizes.css';

.root {
  position: relative;
}

.search-input {
  display: block;
  width: 100%;
  margin-right: 2.5rem;

  @media (max-width: $screenMobileMax) {
    margin-right: 0;
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}

.clear-icon {
  display: none;
  margin-left: 0.5rem;
  cursor: pointer;
}

.input {
  padding: 0.375rem 2rem 0.375rem 0.875rem;

  &--left-icon {
    padding-left: 0;
  }
}

.input-root {
  display: flex;
  padding: 0;
}

.search-icon {
  width: 0.75rem;
  height: 0.75rem;

  &--left {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
