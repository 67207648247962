@import '_colors';
@import '_fonts';
@import '_sizes.css';

.modal {
  color: $cool-gray-0;
  font-family: $main-font-family;

  &__paper {
    max-width: 56.25rem;

    &--small {
      max-width: 20rem;
    }

    &--medium {
      max-width: 25rem;
    }

    &--large {
      max-width: 40rem;
    }

    &:not(.fixed-width) {
      @media (max-width: $screenMobileMax) {
        max-width: 100%;
      }
    }
  }

  &__content {
    padding: 1.25rem;
    color: $cool-gray-0;
  }

  &__title {
    padding: 1.25rem;
    color: $cool-gray-0;
    font-weight: 400;
    font-size: $font-size-xxxl;
    border-bottom: 1px solid $cool-gray-plus-60;

    &--no-border {
      border-bottom: none;
    }
  }

  &__close-button {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    width: 1.25rem;
    min-width: auto;
    height: 1.25rem;
    padding: 0;
    color: $cool-gray-20;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 1px solid $cool-gray-plus-60;

    &--no-border {
      border-top: none;
    }

    @media (max-width: $screenMobileMax) {
      flex-direction: column;
    }
  }

  &__close {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
}
