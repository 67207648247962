@import '_colors.css';
@import '_mixins.css';

.profile-menu {
  &__content {
    width: 20rem;
    padding: 0.5rem 1.125rem 1.125rem;
  }

  &__button {
    font-weight: 600;
    border: 2px solid transparent;
    border-radius: 8px;
  }

  &__popover {
    border: 1px solid $purple-10;
    box-shadow: 0 4px 8px rgb(0 0 0 / 20%), 0 2px 4px rgb(0 0 0 / 20%);
  }

  &__dropdown-item {
    margin: 0;
    padding: 0.5rem 0;
    background: none;

    *:global(:not(.MuiTouchRipple-root)) {
      padding: 2px;
    }

    &:hover {
      /* special case where it needs to rewrite common style of common menu item */
      text-decoration-color: $cool-gray-20 !important;
      background: none !important;

      span:global(:not(.MuiTouchRipple-root)) {
        @mixin inner-underline;
      }

      a:global(:not(.MuiTouchRipple-root)) {
        @mixin inner-underline;
      }
    }

    &--grouped {
      margin: 0;
      padding: 0.4rem 0;
    }

    &--badged {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__dropdown-item-content {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0 !important;
  }

  &__messages {
    box-sizing: border-box;
    width: 100%;
    padding-left: 2.5rem !important;
  }
}
