@import '_colors.css';
@import '_sizes.css';

.select-container {
  display: flex;
  align-items: center;

  @media (max-width: $screenMobileMax) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.select {
  min-width: 16rem;
  margin-left: 0.5rem;

  @media (max-width: $screenMobileMax) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 0.5rem;
  }
}

.select-item {
  display: flex;
}

.count {
  margin-left: 0.5rem;
}

.info-container {
  display: flex;
  align-items: center;
}

.info-button {
  width: auto;
  min-width: auto;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0;

  &:hover {
    background-color: $primary-green-plus-90 !important;
  }
}

.icon {
  margin-right: 0.5rem;
}
