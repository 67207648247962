@import '_sizes.css';

.user-activity-modal {
  &__paper {
    max-width: 23.25rem;

    @media (max-width: $screenMobileMax) {
      max-width: 21rem;
    }
  }

  &__content {
    padding: 1.25rem;

    @media (max-width: $screenMobileMax) {
      padding: 0;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    margin-top: 1.5rem;
  }

  &__action {
    min-width: 8.625rem;
    font-weight: 700;
    text-transform: none;
  }

  &__header {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
}
