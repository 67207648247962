@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';

.typography {
  &--internal-error,
  &--hxl,
  &--h1,
  &--h2,
  &--h3,
  &--h4,
  &--h5,
  &--h6,
  &--body1,
  &--body2,
  &--body1-bold,
  &--body1-underline,
  &--body2-bold,
  &--body2-underline,
  &--helper,
  &--avatar {
    color: $cool-gray-20;
  }

  &--internal-error {
    font-weight: 600;
    font-size: $font-size-internal-error-title;
    line-height: $line-height-internal-error-title;

    @media (max-width: $screenTabletMax) {
      font-weight: 600;
      font-size: $font-size-sign-in-title;
      line-height: $line-height-sign-in-title;
    }

    @media (max-width: $screenMobileMax) {
      font-weight: 600;
      font-size: $font-size-6xl;
      line-height: $line-height-huge;
    }
  }

  &--hxl {
    font-weight: 600;
    font-size: $font-size-sign-in-title;
    line-height: $line-height-sign-in-title;

    @media (max-width: $screenMobileMax) {
      font-weight: 300;
      font-size: $font-size-xxxxl;
      line-height: $line-height-7xl;
    }
  }

  &--h1 {
    font-weight: 300;
    font-size: $font-size-xxxxxl;
    line-height: $line-height-huge;

    @media (max-width: $screenTabletMax) {
      font-weight: 300;
      font-size: $font-size-xxxxl;
      line-height: $line-height-7xl;
    }

    @media (max-width: $screenMobileMax) {
      font-weight: 300;
      font-size: $font-size-xxl;
      line-height: $line-height-xxx2l;
    }
  }

  &--h2 {
    font-weight: 600;
    font-size: $font-size-xxxl;
    line-height: $line-height-xxxxxl;

    @media (max-width: $screenTabletMax) {
      font-weight: 600;
      font-size: $font-size-xxxl;
      line-height: $line-height-xxxxxl;
    }

    @media (max-width: $screenMobileMax) {
      font-weight: 600;
      font-size: $font-size-xxl;
      line-height: $line-height-xxxl;
    }
  }

  &--h3 {
    font-weight: 500;
    font-size: $font-size-xxl;
    line-height: $line-height-xxxl;

    @media (max-width: $screenTabletMax) {
      font-weight: 500;
      font-size: $font-size-xxl;
      line-height: $line-height-xxxxl;
    }

    @media (max-width: $screenMobileMax) {
      font-weight: 500;
      font-size: $font-size-l;
      line-height: $line-height-xxxl;
    }
  }

  &--h4 {
    font-weight: 400;
    font-size: $font-size-xl;
    line-height: $line-height-xxx2l;

    @media (max-width: $screenTabletMax) {
      font-weight: 400;
      font-size: $font-size-xl;
      line-height: $line-height-xxx2l;
    }

    @media (max-width: $screenMobileMax) {
      font-weight: 500;
      font-size: $font-size-l;
      line-height: $line-height-xxxl;
    }
  }

  &--h5 {
    font-weight: 500;
    font-size: $font-size-l;
    line-height: $line-height-xxxl;
  }

  &--h6 {
    font-weight: 600;
    font-size: $font-size-m;
    line-height: $line-height-xxxl;
  }

  &--body0 {
    font-weight: 400;
    font-size: $font-size-l;
    line-height: $line-height-xxxl;
    white-space: pre-wrap;
  }

  &--body1 {
    font-weight: 400;
    font-size: $font-size-m;
    line-height: $line-height-xxxl;
    white-space: pre-wrap;

    &-bold {
      font-weight: 600;
    }

    &-underline {
      text-decoration: underline;
    }
  }

  &--body2 {
    font-weight: 400;
    font-size: $font-size-s;
    line-height: $line-height-xxxl;
    white-space: pre-wrap;

    &-bold {
      font-weight: 600;
    }

    &-underline {
      text-decoration: underline;
    }
  }

  &--helper {
    font-weight: 400;
    font-size: $font-size-xs;
    line-height: $line-height-xl;
    white-space: pre-wrap;
  }

  &--avatar {
    font-weight: 500;
    font-size: $font-size-m;
    line-height: $line-height-xl;
    text-transform: uppercase;

    @media (max-width: $screenMobileMax) {
      font-weight: 500;
      font-size: $font-size-xs;
      line-height: $line-height-xl;
    }
  }
}
