@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';
@import '_mixins.css';

@define-mixin selected-card {
  background-color: $primary-green-plus-90;

  @mixin outline-mixin 8px, 3px, $primary-green;
}

.wellness-card-wrapper {
  position: relative;

  @media (max-width: $screenTabletMax) {
    width: 49%;
    max-width: 49%;
  }

  @media (max-width: $screenMobileMax) {
    width: 100%;
    max-width: 100%;
  }
}

.wellness-card {
  position: relative;
  box-sizing: border-box;
  max-width: 24rem;
  min-height: 8.5rem;
  padding: 1rem;
  overflow: visible;
  border-radius: 8px;
  box-shadow: none;

  @mixin outline-mixin 8px, 1px, $cool-gray-plus-30;

  @media (max-width: $screenTabletMax) {
    width: 100%;
    max-width: 100%;
  }

  &--selected {
    @mixin selected-card;
  }

  &__header {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &__activity-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    text-transform: uppercase;

    &--icon {
      color: $white;
    }
  }

  &__most-recent-entry {
    color: $cool-gray-plus-20;
    font-weight: 400;
    font-size: $font-size-s;

    &--selected {
      color: $cool-gray-20;
    }
  }

  &__most-recent-entry-title {
    margin-bottom: 0.5rem;
  }

  &__source {
    text-transform: capitalize;
  }
}
