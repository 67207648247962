@import '_animation.css';
@import '_colors.css';
@import '_mixins.css';

.locker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $black-opacity-20;

  &--animated {
    @mixin pulsation 1s, 1.1;
  }

  &--fullscreen {
    position: fixed;
    z-index: 9999;
  }

  &__logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.5rem;
    height: 7.5rem;
    margin-bottom: 1.5rem;
    background: $white;
    border-radius: 50%;
  }

  &__dashes {
    position: absolute;
    top: calc(50% - 3rem);
    left: calc(50% - 3rem);
    width: 6rem;
    height: 6rem;

    &--animated {
      @mixin spin 5s;
    }
  }

  &__marathon-logo {
    width: 4rem;
    height: 4rem;
  }

  &__title {
    color: $white;

    @mixin text-shadow;
  }
}
