@import '_colors.css';

.picker-button {
  display: contents;
}

/* Due to Figma design, there is no such size in FaIcon sizes
/ TODO check icon sizes, extract to shared if needed */
.picker-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.calendar-button {
  color: $gray-20;

  &:hover,
  &:focus {
    background-color: $purple-plus-80;
  }

  &:focus-visible {
    color: $gray-20;
    background-color: $purple-plus-80 !important;
    border: 1px solid $purple !important;
  }
}

.active {
  border: 1px solid $purple !important;
}

.selected {
  color: $white;
  background-color: $purple !important;

  &:hover {
    color: $gray-20;
    background-color: $purple-plus-80 !important;
  }
}
