.root {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  outline: none;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}
