@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';

.close-button {
  color: $black;
  font-size: $font-size-m;
  cursor: pointer;

  @media (max-width: $screenMobileMax) {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
