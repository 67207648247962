@import '_colors.css';

.attachments-info-container {
  display: flex;
  color: $cool-gray-plus-20;
}

.attachments-info-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}
