@import '_sizes.css';

.wellness-card-skeleton {
  max-width: 24rem;
  height: 9rem;
  border-radius: 8px;

  @media (max-width: $screenTabletMax) {
    width: 49%;
    max-width: 49%;
  }

  @media (max-width: $screenMobileMax) {
    width: 100%;
    max-width: 100%;
  }
}
