@import '_colors.css';
@import '_sizes.css';
@import '_mixins.css';

$desktopPadding: 4.375rem;
$tabletPadding: 2.75rem;

.root {
  position: relative;
  display: flex;
  min-width: $screenMobileMin;
  padding: $desktopPadding;

  @mixin marathon-fire-background;

  @media (max-width: $screenTabletMax) {
    flex-direction: column;
    padding: $tabletPadding;
  }
}

.wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  max-width: 52%;
  padding-bottom: 4rem;

  @media (max-width: $screenMobileMax) {
    justify-content: flex-start;
    width: 100%;
    min-width: auto;
    max-width: none;
  }
}

.container {
  max-width: 25rem;

  @media (max-width: $screenMobileMax) {
    max-width: inherit;
  }
}

.title {
  margin-bottom: 1.5rem;
}

.button-container {
  display: flex;
  justify-content: space-between;
}
