@import '_colors.css';

.password-field {
  &__toggle-visibility {
    all: unset;
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid $cool-gray-plus-20;
    }

    &:hover,
    &:focus {
      color: $purple-10;
      background-color: $primary-green-plus-90;
    }

    &-text {
      margin-left: 6px;
      vertical-align: top;
    }
  }
}
