@import '_colors.css';
@import '_mixins.css';

.icon-button {
  &--custom-focus {
    &:focus-visible {
      @mixin custom-focus-visible;
    }
  }
}
