@import '_colors.css';
@import '_sizes.css';

.messages {
  &__new-message {
    &--icon {
      color: $white;
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  &__type-menu {
    box-sizing: border-box;
    width: 100%;
    max-width: 24rem;
    margin-bottom: 1rem;
  }

  &__search {
    box-sizing: border-box;
    width: 100%;
    max-width: 24rem;
    margin-bottom: 1.5rem;
  }

  &__layout-container {
    display: flex;
  }

  &__list-container {
    flex: 1 1 auto;
    max-width: 24rem;
    margin-right: 1rem;

    @media (max-width: $screenTabletMax) {
      max-width: none;
      margin-right: initial;
    }
  }

  &__message-box-container {
    flex: 1 1 0;
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__linked-family-members {
    margin-bottom: 0.5rem;
  }
}
