@import '_mixins.css';
@import '_sizes.css';
@import '_colors.css';
@import '_fonts.css';

.field {
  padding-bottom: 2.5rem;

  &--size-big {
    padding-bottom: 2.5rem;
  }

  &--size-small {
    padding-bottom: 1.5rem;
  }

  &--size-msmall {
    padding-bottom: 1rem;
  }

  &--size-xsmall {
    padding-bottom: 0.5rem;
  }

  &--font-xxl {
    font-weight: 500;
    font-size: $font-size-xxl;
  }

  &--font-l {
    font-weight: 500;
    font-size: $font-size-l;
  }

  &--font-s {
    font-size: $font-size-s;
  }

  &--font-xs {
    font-size: $font-size-xs;
  }

  @media (max-width: $screenTabletMax) {
    padding-bottom: 1.5rem;
  }

  &__label {
    display: flex;

    &--gray {
      color: $cool-gray-20;
    }

    &--error {
      color: $error-0;
    }
  }

  &__body {
    margin-top: 0.5rem;
  }

  &__asterisk {
    @mixin field-asterisk;

    font-size: $font-size-m;

    &--size-small {
      font-size: $font-size-xs;
    }

    &--font-xxl {
      font-weight: 500;
      font-size: $font-size-xxl;
    }

    &--font-l {
      font-weight: 500;
      font-size: $font-size-l;
    }

    &--font-s {
      font-size: $font-size-s;
    }

    &--font-xs {
      font-size: $font-size-xs;
    }
  }

  &__required {
    color: $cool-gray-plus-20;
    font-size: $font-size-xs;
  }
}

.no-indent {
  margin: 0;
  padding: 0;
}
