@import '_sizes.css';

.wellness-log {
  position: relative;
  padding-top: 1.5rem;

  &__title {
    margin-bottom: 1.5rem;
  }

  &__dashboard {
    width: 100%;
  }

  &__inner {
    padding: 0;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 24rem;

    @media (max-width: $screenTabletMax) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 100%;
    }

    @media (max-width: $screenMobileMax) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
    }
  }

  &__content {
    width: 100%;
  }

  &__card {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__connect-my-device {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screenMobileMax) {
      display: block;
    }
  }

  &__connect-button {
    width: 11.375rem;
    margin-bottom: 1.3rem;

    @media (max-width: $screenMobileMax) {
      width: 100%;
    }
  }
}
