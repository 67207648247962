@import '_z-index.css';
@import '_fonts.css';
@import '_sizes.css';
@import '_mixins.css';

.horizontal-menu {
  z-index: $z-index-main-menu;
  display: flex;
  flex-direction: row;
  padding-top: 0;
  padding-bottom: 0;
  font-size: $font-size-l;

  &__tab {
    display: flex;
    flex-direction: column;
    min-width: initial;
    min-height: 2.75rem;
    margin-top: 0.375rem;
    padding-top: 0.75rem;
    padding-bottom: 0;
    font-weight: 600;

    &--selected,
    &--hovered,
    &:hover {
      &::after {
        display: block;
        width: 100%;
        height: 4px;
        background-color: $primary-green;
        content: '';
      }
    }

    &--focus-visible {
      .horizontal-menu__label-text {
        border-radius: 0.25rem;

        @mixin custom-focus-visible;
      }
    }
  }

  &__label-wrapper {
    position: relative;
    flex: 1 1 100%;
    font-size: 1rem;

    &--has-submenu {
      padding-right: 1.5rem;
    }
  }

  &__label-submenu-icon {
    position: absolute;
    right: -0.25rem;
    margin-top: -1px;
  }
}
