@import '_colors.css';
@import '_fonts.css';
@import '_animation.css';
@import '_mixins.css';

.select {
  &--outlined {
    border-radius: 6px;
  }

  &__control-root {
    &--outlined-with-label {
      margin-top: 1.5rem;
    }
  }

  &__root {
    &:focus {
      background-color: $primary-green-plus-90;
    }
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border-color: $cool-gray-plus-20;
    border-width: 2px;
  }

  :global(.Mui-disabled) {
    color: $cool-gray-plus-20;
  }

  &__input {
    @mixin input-styles;

    &:global(.Mui-focused) {
      fieldset {
        border-color: $primary-green !important;
      }

      @mixin field-focused;
    }
  }

  &__icon {
    color: $cool-gray-20;
  }

  &__loading-icon {
    transform-origin: center;

    @mixin spin 1s;
  }

  &__menu {
    &--outlined {
      border: 2px solid $primary-green;
    }

    &:global(.Mui-selected) {
      background-color: $primary-green-plus-90;
    }
  }

  &__input-outlined {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__clear-button {
    position: absolute;
    right: 2rem;
    color: $cool-gray-20;
    cursor: pointer;
  }
}

.search-container {
  padding: 0 6px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-left: -10px;

  &.checkbox {
    margin: 0 8px;
    padding: 0;

    &:global(.Mui-checked) {
      margin: 0 10px;
      padding: 0;
    }
  }
}

.empty-results {
  line-height: $line-height-xl;
}
