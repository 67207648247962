@import '_colors.css';
@import '_sizes.css';
@import '_mixins.css';

.app-bar {
  color: $cool-gray-0;
  background-color: $white;
  box-shadow: 0 2px 4px $black-opacity-15;

  @mixin header-padding;

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: $screenDesktopMin;
    margin: auto;
  }
}
