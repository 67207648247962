@import '_sizes.css';
@import '_fonts.css';
@import '_mixins.css';
@import '_z-index.css';

.burger-menu {
  &__accordion {
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  &__item {
    align-items: flex-end;
    min-height: 48px;
    padding: 0 0.5rem 0.75rem;

    &--bold {
      font-weight: 600;
    }

    &:global(.Mui-focusVisible) {
      background: transparent;
    }
  }

  &__sub-menu {
    padding: 0;

    &-sublabel {
      font-size: 0.75rem;
    }
  }

  &__sub-menu-details {
    padding: 0.5rem;
  }

  &__accordion-summary {
    align-items: flex-end;
    padding-right: 0;
    padding-left: 0;

    &:global(.Mui-focusVisible) {
      background: transparent;
      border-radius: 0.25rem;

      @mixin custom-focus-visible 0;
    }

    &:global(.MuiAccordionSummary-root) {
      min-height: initial;
    }
  }

  &__accordion-summary-content {
    margin-bottom: 0;
  }
}
