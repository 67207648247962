@import '_sizes.css';

.actions {
  display: flex;
  justify-content: space-between;

  @media (max-width: $screenMobileMax) {
    box-sizing: border-box;
    width: 100%;
  }
}

.action-button {
  margin-left: 1rem;

  &:first-child {
    margin: initial;
  }

  @media (max-width: $screenMobileMax) {
    flex: 1 1 auto;
    box-sizing: border-box;
  }
}
