@import '_colors.css';
@import '_fonts.css';

.markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $cool-gray-20;
  }

  h1 {
    font-weight: 300;
    font-size: $font-size-xxxxl;
  }

  h2 {
    font-weight: 600;
    font-size: $font-size-xxxl;
  }

  h3 {
    font-weight: 500;
    font-size: $font-size-xxl;
  }

  h4 {
    font-size: $font-size-xl;
  }

  h5 {
    font-weight: 500;
    font-size: $font-size-l;
  }

  h6 {
    font-weight: 500;
    font-size: $font-size-m;
  }

  a {
    color: $purple;
  }

  ul,
  ol {
    padding-left: 1rem;
  }

  p,
  div {
    white-space: pre-wrap;
  }
}
