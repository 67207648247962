@import '_sizes.css';

.default-clinic-phone {
  &__spinner {
    align-self: center;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__clinic-name {
    margin-bottom: 0.5rem;
  }

  &__anywhere-name {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  &__other-section {
    margin-top: 1rem;
    text-align: center;
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1rem;
  }

  &__button {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__mobile-action {
    margin-top: auto;
    margin-bottom: 2.5rem;
    border-top: none;
  }

  &__mobile-button {
    width: 100%;
  }
}
