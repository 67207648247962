@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';
@import '_mixins.css';

@define-mixin text-styles {
  width: 100%;
  margin: 1rem 0;
}

.root {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  min-height: max(100vh, 45rem);
  padding: 2rem;
  overflow-x: hidden;
  color: $cool-gray-40;

  @mixin marathon-fire-background;

  @media (max-width: $screenMobileMax) {
    background-image: url('@assets/mh-watermark.svg');
    background-repeat: no-repeat;
    background-position: bottom -7rem right -2rem;
    background-size: 20rem;
  }
}

.logo {
  width: 19rem;
  height: 6.25rem;
  margin-bottom: 3rem;
  cursor: pointer;
}

.heading {
  @mixin text-styles;

  &--service-error {
    color: $purple;
  }

  &--not-found {
    color: $primary-green;
  }

  &--maintenance {
    color: $deep-blue;
  }
}

.sub-text {
  @mixin text-styles;

  padding-bottom: 1rem;
  font-weight: 500;
  font-size: $font-size-xxl;

  @media (max-width: $screenMobileMax) {
    font-size: $font-size-l;
  }

  &--not-found {
    color: $black;
  }

  &--service-error {
    max-width: 45rem;
  }

  &--maintenance {
    font-size: $font-size-6xl;

    @media (max-width: $screenMobileMax) {
      font-size: $font-size-xxl;
    }
  }
}

.sub-heading {
  margin: 1.5rem 0;
  font-weight: 500;
  font-size: $font-size-l;

  @media (max-width: $screenMobileMax) {
    font-size: $font-size-m;
  }

  &--not-found {
    color: $black;
  }

  &--maintenance {
    max-width: 30rem;
    font-size: $font-size-xxl;

    @media (max-width: $screenMobileMax) {
      font-size: $font-size-l;
    }
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0;
}

.button {
  padding: 1rem 0;
  color: $cool-gray-40;
  font-weight: 500;
  font-size: $font-size-xxl;

  svg {
    color: $cool-gray-40;
  }

  &:hover {
    background: none !important;

    svg {
      color: $primary-green;
    }
  }

  @media (max-width: $screenMobileMax) {
    font-size: $font-size-m;
  }
}

.picture-container {
  position: fixed;
  top: 5.8125rem;
  right: 10rem;

  @media (max-width: $screenTabletMax) {
    top: calc(50% - 19rem);
    right: -1rem;
  }

  @media (max-width: $screenMobileMax) {
    top: 32%;
    right: -1rem;
  }

  &--not-found {
    right: 3rem;
  }

  &--maintenance {
    top: inherit;
    right: 0;
    bottom: 2rem;

    @media (max-width: $screenTabletMax) {
      top: inherit;
      right: -3rem;
      bottom: 2rem;
    }

    @media (max-width: $screenMobileMax) {
      top: inherit;
      right: -5rem;
      bottom: 1rem;
    }
  }
}

.gratitude {
  margin-top: 2.625rem;
  font-size: $font-size-xl;
}

.error-container {
  position: absolute;
  right: 3.75rem;
  bottom: 0;

  @media (max-width: $screenMobileMax) {
    position: relative;
    right: initial;
    bottom: initial;
    display: flex;
    justify-content: flex-end;
  }
}

.error-code {
  font-weight: 100;
  font-size: $font-size-service-page-laptop;
  line-height: unset;
  text-shadow: 0 0.375rem 0.375rem rgb(0 0 0 / 25%);

  @media (max-width: $screenTabletMax) {
    font-size: $font-size-service-page-tab;
  }

  @media (max-width: $screenMobileMax) {
    font-size: $font-size-service-page-mobile;
  }

  &--not-found {
    color: $purple-10;
  }
}

.error-text {
  position: relative;
  top: 2.7rem;
  margin-left: 2.7rem;
  font-weight: 500;
  font-size: $font-size-xxxxl;

  @media (max-width: $screenMobileMax) {
    top: 1.58rem;
    margin-left: 1.58rem;
    font-size: $font-size-m;
  }

  &--not-found {
    color: $purple-10;
  }
}
