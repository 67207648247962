@import '_colors';

.safe-html {
  table {
    margin: 0.5em 0;
    border: 1px solid $cool-gray-plus-60;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0.25em 0.5em;
    border: 1px solid $cool-gray-plus-60;
  }

  a {
    color: $purple;

    &:hover {
      color: $purple;
    }
  }

  ul,
  ol {
    padding-left: 1rem;
  }

  p,
  div {
    white-space: pre-wrap;
  }
}
