@import '_fonts.css';
@import '_sizes.css';
@import '_colors.css';
@import '_mixins.css';

.profile-button {
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 3rem;
  height: 3rem;
  font-size: $font-size-m;
  border: 2px solid $cool-gray-0;
  border-radius: 50%;
  cursor: pointer;

  &:focus-visible {
    @mixin custom-focus-visible;
  }

  &__text {
    font-weight: 500;
    font-size: $font-size-l;
    letter-spacing: 0.05em;
  }

  &__message-circle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.75rem;
    height: 0.75rem;
    background: $magenta-0;
    border: 1px solid $white;
    border-radius: 50%;
  }
}
