.root {
  padding-left: 2.75rem;

  &--only-current {
    width: auto;
    margin-left: auto;
    padding-left: 0;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}
