@import '_colors.css';

.clock-icon {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.icon-button {
  margin-right: -0.5rem;
}

.time-picker {
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
    background: none;
  }
}
