@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';

.list {
  &__item {
    display: inline-flex;
    gap: 24px;
    align-items: center;
    align-self: stretch;
    justify-content: flex-start;

    &--title {
      font-weight: 600;
    }
  }
}

.application-buttons {
  &__link {
    display: inline-block;
    margin-right: 1rem;
    margin-right: 0;
    font-size: $font-size-xl;
    cursor: pointer;
  }
}

.description {
  display: flex;
  flex: 1 1 55%;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 30%;
  margin-right: 1rem;
  margin-bottom: 2.5rem;
  color: $cool-gray-20;

  @media (max-width: $screenTabletMax) {
    flex-grow: 0;
    justify-content: flex-start;
    width: 100%;
    min-width: auto;
    max-width: none;
    margin-top: 3.5rem;
    padding: 0 5rem;
  }

  @media (max-width: $screenMobileMax) {
    margin-top: 0;
    padding: 0 2rem;
  }

  &__logo {
    width: 19rem;
    height: 6.25rem;
  }

  &__logo-container {
    margin: 0;
    margin-bottom: 4rem;
    font-weight: 400;
    font-size: $font-size-m;
  }

  &__title {
    margin-bottom: 1rem;

    @media (max-width: $screenMobileMin) {
      font-size: $font-size-xxxl;
    }
  }

  &__sub-title {
    color: $cool-gray-20;
  }

  &__information {
    display: flex;
    flex-direction: column;
    gap: 64px;
    align-items: flex-start;

    @media (max-width: $screenMobileMax) {
      gap: 1rem;
      align-items: start;
      align-self: stretch;
      justify-content: center;
      padding: 1rem;
      background: $white;
      border-radius: 10px;
    }
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    align-self: stretch;
  }

  &__accordion {
    padding: 0;
    color: $cool-gray-20;
    background: none;
    border: none;
    box-shadow: none;
  }

  &__accordion-summary {
    margin: 0;
    padding: 0;

    &--expanded {
      min-height: initial !important;
      margin: 0 !important;
    }

    &--content {
      margin: 0 !important;
    }
  }

  &__accordion-details {
    padding: 0;
  }

  &__accordion-icon {
    width: 1.8rem;
    height: 1.8rem;
    fill: $cool-gray-20;
  }

  &__use-to-items {
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 500;
    line-height: 1.75rem;
    list-style-position: inside;
  }

  &__additional {
    font-size: $font-size-s;
  }
}
