@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';

.snackbar {
  min-width: 40rem !important;
  padding: 0.25rem 1rem !important;
  border-radius: 0.5rem !important;

  @media (max-width: $screenTabletMax) {
    min-width: 30rem !important;
  }

  @media (max-width: $screenMobileMax) {
    min-width: 90% !important;
  }

  &--success {
    color: $success-20 !important;
    background-color: $success-plus-45 !important;
    border: 1px solid $success-10 !important;

    div div * {
      color: $success-20;
    }
  }

  &--error {
    background-color: $error-plus-45 !important;
    border: 1px solid $error-20 !important;

    div div * {
      color: $error-30;
    }
  }

  &--warning {
    background-color: $gold-plus-30 !important;
    border: 1px solid $gold-20 !important;

    div div * {
      color: $cool-gray-20;
    }
  }

  &__icon {
    align-self: baseline;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;

    &--error {
      color: $error-0;
    }

    &--warning {
      color: $gold-20;
    }
  }

  :global(.SnackbarItem-message) {
    padding: 8px 8px 8px 0 !important;
  }
}
