@import '_colors.css';
@import '_sizes.css';
@import '_fonts.css';

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;
  color: $cool-gray-0;

  &__link {
    margin-left: 1.875rem;
    font-size: $font-size-m;
    line-height: 1.5rem;
    text-align: left;

    @media (max-width: $screenMobileMax) {
      margin: 0 0  1rem;
    }
  }

  @media (max-width: $screenTabletMax) {
    padding: 1rem;
  }

  @media (max-width: $screenMobileMax) {
    flex-direction: column;
    justify-content: flex-start;
  }
}
