@import '_colors.css';

.icon {
  transition-duration: 0.25s !important;

  &--small {
    width: 1rem;
    height: 1rem;
  }

  &--medium {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--large {
    width: 40px;
    height: 40px;
  }

  &--xl {
    width: 60px;
    height: 60px;
  }

  &--no-hover {
    color: unset;
  }
}

.gray {
  color: $cool-gray-0;

  &--important {
    color: $cool-gray-0 !important;
  }
}

.magenta {
  color: $magenta-0;

  &--important {
    color: $magenta-0 !important;
  }
}

.dark-green {
  color: $secondary-green-0;

  &--important {
    color: $secondary-green-0 !important;
  }
}

.red {
  color: $error-red-0;

  &--important {
    color: $error-red-0 !important;
  }
}

.green {
  color: $green-0;

  &--important {
    color: $green-0 !important;
  }

  &-inverted--fad {
    --fa-primary-color: $secondary-green-20;
    --fa-secondary-color: $green-0;
    --fa-secondary-opacity: 1;
  }

  &--fad {
    --fa-primary-color: $green-0;
    --fa-secondary-color: $secondary-green-20;
    --fa-secondary-opacity: 1;
  }
}

.purple {
  color: $purple-20;

  &--important {
    color: $purple-20 !important;
  }

  &--fad {
    --fa-primary-color: $purple-20;
    --fa-secondary-color: $purple-plus-40;
    --fa-secondary-opacity: 1;
  }
}

.purple-inverted {
  &--fad {
    --fa-primary-color: $white;
    --fa-secondary-color: $purple-plus-40;
    --fa-secondary-opacity: 1;
  }
}

.blue {
  color: $deep-blue-20;

  &--important {
    color: $deep-blue-20 !important;
  }

  &--fad {
    --fa-primary-color: $deep-blue-20;
    --fa-secondary-color: $deep-blue-plus-40;
    --fa-secondary-opacity: 1;
  }
}

.teal {
  color: $teal-0;

  &--important {
    color: $teal-0 !important;
  }

  &--fad {
    --fa-primary-color: $teal-0;
    --fa-secondary-color: $teal-20;
    --fa-secondary-opacity: 1;
  }
}

.white {
  color: $white;

  &--important {
    color: $white !important;
  }

  &--fad {
    --fa-primary-color: $white;
    --fa-secondary-color: $white;
    --fa-secondary-opacity: 1;
  }
}
