@import '_colors.css';

.controller {
  &__yes {
    padding: 0.375rem 1rem;
  }

  &__no {
    padding: 0.375rem 1rem;
  }
}
