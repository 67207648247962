@import '_colors.css';
@import '_fonts.css';
@import '_mixins.css';

.text-field {
  &--focused {
    &:global(.Mui-focused) {
      fieldset {
        border-color: $purple !important;
      }
    }

    @mixin field-focused;
  }

  &__label {
    @mixin field-label;

    &--focused {
      @mixin field-label-focused;
    }

    &--disabled {
      &:global(.Mui-disabled) {
        color: $cool-gray-plus-20;
      }
    }
  }

  &__label-asterisk {
    @mixin field-asterisk;
  }

  &__input {
    @mixin input-styles;

    &--outlined {
      padding: 1rem;
    }
  }

  &__input-root {
    &--outlined {
      border: 1px solid $cool-gray-plus-20;
      border-radius: 6px;

      &:focus {
        @mixin border 6px, 2px, $purple-10;
      }

      &::after {
        content: none;
      }

      &::before {
        content: none;
      }
    }
  }

  input::placeholder,
  textarea::placeholder {
    color: $cool-gray-plus-20;
    opacity: 1;
  }
}
