@import '_colors.css';

.root {
  padding: 0.25rem 1.125rem;
  color: $cool-gray-40;
  font-weight: 600;
  font-size: 1.125rem;
  background: $gold-plus-10;
  border: 2px solid $cool-gray-40;
  border-radius: 40px;
}

.sub {
  font-size: 0.875rem;
}
