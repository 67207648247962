@import '_colors.css';
@import '_sizes.css';
@import '_z-index.css';
@import '_fonts.css';

.sub-menu {
  z-index: 3;

  &__list {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__menu-item {
    min-width: 15rem;
    padding: 1rem;
    font-size: 1rem;

    &--select {
      font-weight: 600;
    }

    &-sublabel {
      font-size: 0.75rem;
    }
  }
}
