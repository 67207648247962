@import '_colors.css';
@import '_fonts.css';

.attachment {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  color: $cool-gray-20;
  background: $gray-50;
  border-radius: 8px;
}

.size {
  margin-top: 0.25rem;
  font-size: $font-size-xs;
}

.attachment-body {
  margin-right: 1.5rem;
}

.icon {
  margin-right: 0.5rem;

  &--delete {
    min-width: unset;
    margin-left: auto;
    padding: 2px;
    cursor: pointer;
    fill: currentcolor;

    span {
      margin-right: unset;
      margin-left: unset;

      svg {
        width: 0.75rem;
        height: 1rem;
        color: $cool-gray-0;
      }
    }
  }
}
