@import '_sizes.css';
@import '_colors.css';

.skip-links {
  position: fixed;
  top: -7.5rem;
  left: 1rem;
  z-index: 1000;
  background-color: white;
  transition: top 195ms cubic-bezier(0.4, 0, 1, 1) 0ms;

  &:focus-within {
    top: 1rem;
  }

  @media print {
    display: none;
  }

  &__link {
    border: 2px solid $purple-10;
    border-radius: 8px;
  }
}
