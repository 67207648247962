@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';
@import '_mixins.css';

.dashboard {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    @media (max-width: $screenTabletMax) {
      margin-bottom: 1rem;
    }
  }

  &__chart {
    max-height: 20rem;

    @media (max-width: $screenMobileMax) {
      max-height: 15rem;
    }
  }

  &__chart-block {
    max-height: 30rem;
    padding: 1rem;
    border: 1px solid $cool-gray-plus-30;
    border-radius: 8px;

    @media (max-width: $screenTabletMax) {
      margin-bottom: 1rem;
    }
  }

  &__button {
    @media (max-width: $screenTabletMax) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 1.5rem;
    color: $cool-gray-plus-20;
  }

  &__subtitle {
    margin-bottom: 1rem;
  }

  &__measurements {
    width: auto;
  }

  &__measurement {
    min-height: 2rem;
    margin: 0.5rem;
    padding: 0;
    color: $purple-10 !important;
    font-size: $font-size-s;
    text-decoration-line: underline;

    &--selected {
      color: $cool-gray-20 !important;
      font-weight: 600;
      text-decoration-line: none;
    }

    &:focus-visible {
      @mixin custom-focus-visible 1px;
    }
  }
}
