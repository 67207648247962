@import '_mixins.css';

.checkbox {
  &__focus-visible &__unchecked-icon {
    @mixin custom-focus-visible 2px, $cool-gray-0;
  }

  &__focus-visible &__checked-icon {
    @mixin custom-focus-visible 2px, $primary-green;
  }

  &__label {
    @mixin checkbox-label;

    margin: 0;

    &--disabled {
      color: $cool-gray-plus-40;
    }
  }

  &__input {
    &--disabled {
      color: $cool-gray-plus-40;
    }

    &:checked {
      color: $primary-green !important;
    }
  }

  &__unchecked-icon {
    border-width: 20px;
    border-radius: 0.25rem;
    fill: none;
    stroke: $cool-gray-0;
  }

  &__checked-icon {
    color: $primary-green;
    border-radius: 0.25rem;

    &--disabled {
      color: $cool-gray-plus-40;
    }
  }

  &__text {
    margin-left: 1.5rem;
  }
}
