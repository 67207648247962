@import '_colors.css';
@import '_fonts.css';
@import '_mixins.css';
@import '_animation.css';

@define-mixin outline-styles {
  color: $primary-green;
  background-color: transparent;
  border: 2px solid $primary-green;
}

@define-mixin contained-styles {
  color: $white;
  background-color: $primary-green;
  border: 2px solid $primary-green;
}

@define-mixin contained-styles-disabled {
  color: $cool-gray-plus-30;
  background-color: $cool-gray-plus-90 !important;
  border: 2px solid $cool-gray-plus-90;
}

@define-mixin outlined-styles-disabled {
  color: $cool-gray-plus-30;
  background-color: transparent;
  border: 2px solid $cool-gray-plus-90 !important;
}

@define-mixin text-styles {
  color: $primary-green;
  background-color: transparent;
}

@define-mixin secondary-styles {
  color: $white;
  background-color: $purple;
  border-color: $purple;
}

@define-mixin secondary-text-styles {
  color: $purple;
  background-color: transparent;
}

@define-mixin secondary-outlined-styles {
  color: $purple;
  background-color: transparent;
  border: 2px solid $purple;
}

@define-mixin warning-styles {
  color: $white;
  background-color: $orange-0;
  border-color: $orange-0;
}

@define-mixin warning-outlined-styles {
  color: $orange-0;
  background-color: transparent;
}

.button {
  padding: 0.5rem 1rem;
  font-size: $font-size-m;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-transform: capitalize;
  border-radius: 40px;
  outline: none;
  box-shadow: none;
  user-select: none;

  &--disabled {
    color: $cool-gray-plus-30 !important;
    background-color: $cool-gray-plus-90 !important;
    border: 2px solid $cool-gray-plus-90 !important;
  }

  &--full-width {
    width: 100%;
  }

  &--small {
    padding: 0.25rem 0.625rem;
    font-weight: 500;
    font-size: $font-size-xs;
    line-height: 18px;

    &:disabled {
      color: $cool-gray-plus-30 !important;
      border: 2px solid $cool-gray-plus-90 !important;
    }

    .button__icon {
      width: 0.75rem;
      height: 0.75rem;

      &--start {
        margin-right: 0.625rem;
        margin-left: 0.25rem;
      }

      &--end {
        margin-right: 0.25rem;
        margin-left: 0.625rem;
      }
    }

    &.button--text {
      &.button--secondary {
        @mixin secondary-text-styles;

        &.button--gray {
          color: $cool-gray-0;
        }

        &:hover:not(.button--no-hover) {
          @mixin secondary-text-styles;

          background-color: $purple-plus-90;
        }
      }

      &.button--primary {
        color: $secondary-green-0;

        &:hover:not(.button--no-hover) {
          color: $secondary-green-40;
        }
      }
    }

    &.button--outlined {
      &.button--secondary {
        @mixin secondary-outlined-styles;

        &:hover:not(.button--no-hover) {
          @mixin secondary-outlined-styles;
        }
      }

      &.button--primary {
        color: $secondary-green-0;
        border: 2px solid $secondary-green-0;

        &:hover:not(.button--no-hover) {
          color: $secondary-green-0;
          border: 2px solid $secondary-green-0;
        }
      }
    }

    &.button--contained {
      text-decoration: none;

      &.button--secondary {
        @mixin secondary-styles;

        &:hover:not(.button--no-hover) {
          @mixin secondary-styles;

          background-color: $purple-40;
        }
      }

      &.button--primary {
        color: $white;
        background-color: $secondary-green-0;
        border: 2px solid $secondary-green-0;

        &:hover:not(.button--no-hover) {
          color: $white;
          background-color: $secondary-green-40;
          border: 2px solid $secondary-green-40;
        }
      }
    }
  }

  &--large {
    padding: 1rem 1.25rem;
    font-size: $font-size-xl;
    line-height: 22px;
  }

  &:hover:not(.button--no-hover) {
    color: $white;
    background-color: $primary-green-40;
    border-color: $primary-green-40;
  }

  &--contained {
    text-decoration: none;

    @mixin contained-styles;

    &:disabled {
      @mixin contained-styles-disabled;
    }

    &.button--no-hover {
      &:hover {
        @mixin contained-styles;
      }
    }
  }

  &--outlined {
    @mixin outline-styles;

    &:disabled {
      @mixin outlined-styles-disabled;
    }

    &:hover:not(.button--no-hover) {
      color: $primary-green;
      background-color: $primary-green-plus-90;
      border-color: $primary-green;
    }

    &.button--no-hover {
      &:hover {
        @mixin outline-styles;
      }
    }
  }

  &--warning {
    @mixin warning-styles;

    &.button--no-hover {
      &:hover {
        @mixin warning-styles;
      }
    }

    &:hover:not(.button--no-hover) {
      background-color: $orange-10;
      border-color: $orange-10;
    }

    &.button--outlined {
      @mixin warning-outlined-styles;

      &:hover:not(.button--no-hover) {
        background-color: $purple-40;
        border-color: $orange-0;
      }

      &.button--no-hover {
        &:hover {
          @mixin warning-outlined-styles;
        }
      }
    }
  }

  &--secondary {
    @mixin secondary-styles;

    &.button--no-hover {
      &:hover {
        @mixin secondary-styles;
      }
    }

    &:hover:not(.button--no-hover) {
      background-color: $purple-40;
      border-color: $purple-40 !important;
    }

    &.button--text {
      @mixin secondary-text-styles;

      &.button--gray {
        color: $cool-gray-0;
      }

      &:hover:not(.button--no-hover) {
        color: $purple;
        background-color: $purple-plus-90;
        border-color: $purple-40;
      }
    }

    &.button--outlined {
      @mixin secondary-outlined-styles;

      &:hover:not(.button--no-hover) {
        color: $purple;
        background-color: $purple-plus-90 !important;
        border: 2px solid $purple;
      }
    }
  }

  &--text {
    @mixin text-styles;

    &.button--gray {
      color: $cool-gray-0;
    }

    &:global(.MuiButton-text) {
      &:hover {
        background: none;
      }
    }

    &:hover:not(.button--no-hover) {
      color: $primary-green;
      background-color: $primary-green-plus-90;
      border-color: $primary-green-plus-90;

      svg {
        color: $primary-green;
      }
    }

    &.button--no-hover:not(:global(.Mui-disabled)) {
      &:hover {
        @mixin text-styles;

        svg {
          color: $primary-green;
        }
      }
    }
  }

  &--purple {
    color: $purple;
  }

  &--underline {
    padding: 0;
    text-decoration: underline;
  }

  &:disabled {
    color: $cool-gray-plus-30;
    background: none;
    cursor: not-allowed;
    pointer-events: none;
  }

  &--high-low {
    max-height: 2rem;
  }

  &__loading-icon {
    transform-origin: center;

    @mixin spin 1s;
  }

  &--focus-visible {
    @mixin custom-focus-visible;

    &--rounded {
      border-radius: 0.25rem;
    }
  }
}
