@import '_z-index.css';
@import '_fonts.css';
@import '_sizes.css';
@import '_mixins.css';

@define-mixin mobile-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $cool-gray-20;
  font-weight: 600;
  font-size: 1.125rem;
  border: 2px solid $cool-gray-plus-20;
}

.module-menu {
  z-index: $z-index-main-menu;
  display: flex;
  flex-direction: row;
  max-width: fit-content;
  padding-top: 0;
  padding-bottom: 0;
  font-size: $font-size-l;
  background: $primary-green-plus-80;
  border-radius: 8px;

  @media (max-width: $screenTabletMax) {
    padding-right: 0;
    padding-left: 0;
    background: transparent;
    box-shadow: none;
  }

  &__tab-root {
    color: $cool-gray-0;

    &:hover {
      color: $white;

      * {
        color: $white;
      }
    }
  }

  &__tab {
    padding: 0.625rem 1.375rem;
    font-weight: 600;
    border-right: 0.0625rem solid $cool-gray-plus-20;

    &:hover {
      background-color: $primary-green-40;

      &:first-child {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }

    &:last-child {
      border-color: transparent;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  &__label-wrapper {
    display: flex;
  }

  &__label-icon {
    margin-right: -10px;
    margin-left: 8px;
    font-size: 20px;
  }

  &__content {
    width: 20rem;
    padding: 0.5rem 1.125rem 1.125rem;
  }
}

.mobile-module-menu {
  z-index: $z-index-main-menu;
  min-height: 48px;
  padding: 0;
  font-size: $font-size-l;
  background: transparent;
  border-radius: 6px;
  box-shadow: none;

  :global(.MuiTabs-scroller) {
    overflow: visible !important;
  }

  :global(.MuiTabs-root) {
    overflow: visible !important;
  }

  &__btn {
    @mixin mobile-btn;

    margin: 0;
    border-radius: 6px;

    &:hover {
      color: $cool-gray-0 !important;
      background-color: $primary-green-plus-90 !important;
    }

    &--active {
      background: $primary-green-plus-90 !important;
    }

    @mixin double-border-focused 8px, $cool-gray-plus-20;
  }

  &__content {
    max-height: 28rem;
    overflow-y: auto;
    border: 0.125rem solid $cool-gray-0;
    border-radius: 0.5rem;
    box-shadow: 0 0.063rem 0.125rem rgb(62 66 70 / 20%), 0 0.125rem 0.25rem rgb(62 66 70 / 20%);

    hr {
      display: none;
    }
  }
}
