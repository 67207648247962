@import '_colors.css';

.form-description-text {
  &__content {
    display: flex;
    gap: 4px;
    padding: 4px;
    color: $dark-gray;
    line-height: 14px;
    background-color: $cool-gray-plus-90;
    border-radius: 4px;
  }
}
