@import '_colors.css';
@import '_mixins.css';
@import '_links.css';

.link {
  @mixin link;

  &--purple {
    @mixin link-color $purple, $purple-40;
  }

  &--gray-10 {
    @mixin link-color $cool-gray-0, $cool-gray-plus-20;
  }

  &--gray-20 {
    @mixin link-color $cool-gray-20, $cool-gray-0;
  }

  &--reverse-underline {
    @mixin reverse-link;
  }

  &--no-underline {
    text-decoration: none;
  }

  &--bold {
    font-weight: 600;
  }

  &--focused,
  &:focus-visible {
    border-radius: 0.125rem;

    @mixin custom-focus-visible;
  }
}
