@import '_colors.css';
@import '_fonts.css';
@import '_sizes.css';

.conversations-list {
  padding: 0;
  margin-block-end: 1em;
  margin-block-start: 1em;

  &--no-messages {
    max-width: none;
  }
}
