@import '_colors.css';
@import '_mixins.css';
@import '_sizes.css';

.fitness-and-steps {
  &__content {
    @media (max-width: $screenMobileMax) {
      display: flex;
      flex-direction: column;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    @media (max-width: $screenMobileMax) {
      flex-direction: column;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &__action {
    flex: 1;
    margin-top: 1.5rem;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $screenMobileMax) {
      margin-top: 0.5rem;
      margin-right: 0;
    }
  }

  &__form {
    padding: 0;
    padding-top: 1rem;
  }

  &__asterisk {
    @mixin field-asterisk;
  }

  &__form-buttons {
    margin-top: 1rem;

    @media (max-width: $screenMobileMax) {
      margin-top: auto;
    }
  }
}
