@import '_colors.css';
@import '_fonts.css';

.side-bar-widget {
  padding: 1rem;

  &--accordion {
    padding: 0.5rem 0;
    box-shadow: none;

    &:global(.Mui-expanded) {
      margin-top: 0;
    }

    &::before {
      top: 0;
      height: 0;
    }
  }

  &__expanded-summary {
    &:global(.Mui-expanded) {
      height: auto;
      min-height: auto;
      margin: 0.5rem 0;
      box-shadow: none;
    }
  }

  &__close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    float: right;
    cursor: pointer;
  }
}
