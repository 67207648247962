@import '_colors.css';
@import '_fonts.css';
@import '_mixins.css';

.menu-item {
  color: $cool-gray-20;
  font-size: $font-size-l;

  &:global(.Mui-selected) {
    background-color: $primary-green-plus-90;
  }

  &--small {
    font-size: $font-size-m;
  }

  &__icon {
    width: 1.765rem;
    height: 1.5rem;
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    text-decoration: none;
    outline: none;
  }

  &--no-select {
    &:global(.Mui-selected) {
      background-color: transparent;
    }
  }

  &:not(.menu-item--no-hover) {
    @mixin menu-item-hover;
  }

  &--no-hover {
    &:hover {
      background-color: transparent;
    }
  }

  &__content {
    width: 100%;
    white-space: pre-wrap;
  }

  &--focus-visible {
    background-color: transparent !important;

    & > .menu-item__content,
    & > .menu-item__link {
      border-radius: 0.25rem;

      @mixin custom-focus-visible;
    }
  }
}
