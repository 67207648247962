@import '_colors.css';
@import '_z-index.css';

.root {
  position: fixed;
  right: 2rem;
  bottom: 10rem;
  z-index: $z-index-portal-scroll-to-top;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  background: $white;
  border: 1px solid $primary-green;
  border-radius: 8px;
  cursor: pointer;
}

.icon {
  width: 2rem;
  color: $primary-green;
}
