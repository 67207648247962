@import '_mixins.css';
@import '_sizes.css';

.form-helper-text {
  position: relative;
  display: flex;
  align-items: baseline;
  white-space: pre-wrap;

  &--error {
    color: $error-0 !important;
  }

  &__icon {
    position: relative;
    top: 0.125rem;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.25rem;
  }
}
