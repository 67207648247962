@import '_sizes.css';

.skeleton {
  width: 100%;
  height: 5rem;
}

.checkbox {
  padding-left: 0;
}
