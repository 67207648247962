@import '_colors.css';

.root {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: $cool-gray-20;
  font-weight: 400;
  font-size: 1rem;
  background: none !important;
  border-radius: 0;

  &--active {
    font-weight: 600;
  }
}

.inner {
  display: inline-block;
  padding: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
