@import '_fonts.css';
@import '_colors.css';
@import '_sizes.css';

.form-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: $screenMobileMax) {
    flex-direction: column-reverse;
  }

  &__line {
    display: flex;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $screenMobileMax) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__action {
    height: 2.25rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $screenMobileMax) {
      flex: 1 1 100%;
      margin-bottom: 1rem;
    }
  }
}
