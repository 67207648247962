@import '_fonts.css';
@import '_colors.css';
@import '_mixins.css';

.profile-side-menu {
  &__user-name {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: $font-size-xxxl;
    text-transform: capitalize;
  }

  &__menu-item {
    margin: 0;
    padding: 0.5rem 0;
    background: none;

    *:global(:not(.MuiTouchRipple-root)) {
      padding: 2px;
    }

    &:hover {
      /* special case where it needs to rewrite common style of common menu item */
      /* special case where it needs to rewrite common style of common menu item */
      text-decoration-color: $cool-gray-20 !important;
      background: none !important;

      span:global(:not(.MuiTouchRipple-root)) {
        @mixin inner-underline;
      }

      a:global(:not(.MuiTouchRipple-root)) {
        @mixin inner-underline;
      }
    }

    &--grouped {
      margin: 0;
      padding: 0.2rem 0;
    }

    &--badged {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__menu-item-content {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0 !important;
  }

  &__messages {
    box-sizing: border-box;
    width: 100%;
    padding-left: 2.5rem !important;
  }

  &__badged {
    flex-direction: column;
    align-items: flex-start;
  }
}
