@import '_colors.css';
@import '_fonts.css';

.pagination-button {
  min-width: auto;
  margin: 0;
  padding: 0.25rem 0.625rem;
  color: $secondary-green-0;
  font-size: $font-size-xs;
  line-height: 0.875rem;
  background: transparent;
  border: none;
  border-radius: 40px;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &--with-margin {
    margin: 0 0.25rem;
  }

  &--selected {
    color: $white;
    background-color: $secondary-green-0;
  }

  &--disabled {
    color: $cool-gray-plus-20;
    cursor: auto;
  }
}
