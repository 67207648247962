@import '_sizes.css';
@import '_mixins.css';
@import '_colors.css';

$desktopPadding: 4.375rem;
$tabletPadding: 2.75rem;

.login {
  position: relative;
  display: flex;
  min-width: $screenMobileMin;
  padding: $tabletPadding 0 0 $desktopPadding;

  @media (max-width: $screenTabletMax) {
    flex-direction: column;
    padding: 0;
    padding-top: $tabletPadding;
  }

  @media (max-width: $screenMobileMax) {
    background: $primary-green-plus-80-opacity-50;
  }

  &__logo {
    width: 19rem;
    height: 6.25rem;
  }

  &__logo-container {
    margin: 0;
    margin-bottom: 4rem;
    font-weight: 400;
    font-size: $font-size-m;
  }

  &__header {
    display: flex;
    flex: 1 1 auto;
    padding: $tabletPadding 0 0 $desktopPadding;
    background: $white;

    @media (max-width: $screenMobileMax) {
      justify-content: center;
      padding: $tabletPadding 0 0 0;
    }

    &--left {
      display: flex;
      flex: 1 1 55%;
      min-width: 30%;
      margin-right: 1rem;

      @media (max-width: $screenMobileMax) {
        flex: unset;
      }
    }

    &--right {
      display: flex;
      flex: 1 1 45%;
      margin-top: -4rem;
      margin-bottom: -4.375rem;
      background: $primary-green-plus-80-opacity-50;
    }
  }
}
