@import '_colors.css';
@import '_fonts.css';

.root {
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  font-weight: 300;
  font-size: $font-size-huge;
  background-color: $white;
  box-shadow: 0 2px 4px rgb(0 0 0 / 30%), -2px 6px 12px rgb(0 0 0 / 20%);
}

.dots {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.time {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  border-radius: 8px;

  &:focus {
    outline: 1px solid $purple;
  }

  &:hover {
    background-color: $purple-plus-80;
  }
}

.arrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.5rem;
}

.icon {
  width: 0.6rem;
  height: 1rem;
  cursor: pointer;
  fill: $cool-gray-20;
}

.am-pm-selection {
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
}

.am-pm-label {
  box-sizing: border-box;
  width: 3rem;
  min-width: initial;
  height: 1.5rem;
  margin-bottom: 0.25rem;
  padding: 0;
  color: $cool-gray-0;
  font-weight: 400;
  font-size: $font-size-s;

  &--selected {
    color: $white;
    background-color: $purple;
    border-radius: 2rem;
  }

  &:global(.Mui-focusVisible) {
    border-radius: 8px;
    outline: 1px solid $purple;
  }

  &:hover {
    color: $cool-gray-20 !important;
    background-color: $purple-plus-80 !important;
    border-radius: 2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
