@import '_fonts.css';
@import '_colors.css';
@import '_sizes.css';
@import '_mixins.css';

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.25rem;

  @media (max-width: $screenTabletMax) {
    padding: 0;
  }

  &__header {
    color: $cool-gray-0;
  }

  &__title {
    margin-bottom: 0.5rem;
  }

  &__description {
    display: none;
    margin-top: 0.5rem;

    &--visible {
      display: block;
    }
  }

  &__asterisk {
    @mixin field-asterisk;
  }

  &__sub-header {
    margin-bottom: 2rem;
    color: $cool-gray-0;
    font-size: $font-size-xs;
  }

  &__alert {
    margin-top: 1rem;
  }

  &__buttons {
    margin-top: auto;
  }
}
