@import '_colors.css';

.accordion {
  &::before {
    display: none !important;
  }

  &__summary-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__accordion-summary {
    &--no-padding {
      padding: 0;
    }
  }

  &__accordion-details {
    &--no-padding {
      padding: 0;
    }
  }

  &__accordion-summary-right {
    text-decoration: underline;
  }
}
