@import '_sizes.css';
@import '_colors.css';
@import '_mixins.css';

.side-menu {
  &__paper {
    width: 24rem;

    @media (max-width: $screenMobileMax) {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 1.5rem $app-bap-general-tablet-padding;

    @mixin header-padding;
  }

  &__close-button {
    all: unset;
    margin-right: 1rem;
    visibility: hidden;
    cursor: pointer;

    &--visible {
      visibility: visible;
    }

    &--right {
      margin-right: 0;
      margin-left: auto;
    }

    &:focus-visible {
      border-radius: 2px;
      outline: 2px solid $cool-gray-plus-20;
    }
  }

  &__close-icon {
    fill: $cool-gray-plus-20;
  }

  &__logo {
    width: 10rem;
    height: 3.125rem;
  }

  &__body {
    padding: 1.5rem $app-bap-general-tablet-padding;

    @mixin header-padding;
  }
}
